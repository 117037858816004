/* eslint-disable max-lines */
/* eslint-disable max-len */

export const REFINEMENT_LIST_CONFIG = [
  { id: 'promotionTag', trackingLabel: 'promotionTag' },
  { id: 'country', trackingLabel: 'country' },
  { id: 'city', trackingLabel: 'destination', searchable: true },
  { injectHierarchical: true },
  { id: 'categoryTheme', trackingLabel: 'category', sortAlphabetical: true },
  { id: 'audience', trackingLabel: 'good_for' },
  { id: 'durationTiming', trackingLabel: 'duration' },
  { id: 'webCategoriesMain', trackingLabel: 'type', hidden: true },
  { id: 'brandTag', trackingLabel: 'brand', sortAlphabetical: true }
];
export const RANGE_LIST_CONFIG = [
  { id: 'availableDates', trackingLabel: 'availableDates', qsId: 'date' }
];
export const HIERARCHICAL_LIST_CONFIG = [
  { id: 'hierarchical.lvl0', trackingLabel: 'categories', sortAlphabetical: true, searchable: true }
];

export const DEFAULT_EXPANDED = {
  availableDates: true,
  city: true,
  categoryTheme: false,
  audience: false,
  duration: false,
  country: true,
  brand: true,
  displayPrice: true,
  'hierarchical.lvl0': true
};

export const REFINEMENT_LIST_CONFIG_V2 = [
  { id: 'promotionTag', trackingLabel: 'promotionTag' },
  { id: 'destination',
    subRefinement: [
      { id: 'country', trackingLabel: 'country' },
      { id: 'city', trackingLabel: 'destination', searchable: true }
    ]
  },
  { id: 'categoryTheme', trackingLabel: 'category', sortAlphabetical: true },
  { injectHierarchical: true },
  { id: 'audience', trackingLabel: 'good_for' },
  { id: 'durationTiming', trackingLabel: 'duration' },
  { id: 'webCategoriesMain', trackingLabel: 'type', hidden: true },
  { id: 'city', trackingLabel: 'destination', searchable: true },
  { id: 'country', trackingLabel: 'country' },
  { id: 'brandTag', trackingLabel: 'brand', sortAlphabetical: true }
];

export const REFINEMENT_LIST_CONFIG_V3 = [
  { id: 'specialCouponCategories', trackingLabel: 'specialCouponCategories', sortAlphabetical: true },
  { id: 'city', trackingLabel: 'destination', sortAlphabetical: true },
  { id: 'country', trackingLabel: 'country', sortAlphabetical: true },
  { injectHierarchical: true },
  { id: 'categoryTheme', trackingLabel: 'category', sortAlphabetical: true },
  { id: 'webCategoriesMain', trackingLabel: 'type', hidden: true },
  {
    id: 'durationTiming',
    hidden: false,
    trackingLabel: 'duration',
    customRefinementLabel: 'durationTimingV2',
    sortAlphabetical: true,
    additionalRefinement: {
      id: 'timingOnly',
      trackingLabel: 'timing',
      sortAlphabetical: true
    }
  },
  { id: 'brandTag', trackingLabel: 'brand', sortAlphabetical: true }
];

export const DEFAULT_EXPANDED_V2 = {
  availableDates: true,
  city: false,
  categoryTheme: false,
  audience: false,
  duration: false,
  country: false,
  brand: false,
  displayPrice: false,
  'hierarchical.lvl0': true
};

export const DEFAULT_ALIASES = {
  hbganaco: {
    2: {
      city: 'Ivy Lea'
    },
    1112537: {
      city: 'Ivy Lea'
    }
  }
};

export const DEFAULT_FEATURED_FILTERS = [
  {
    refinement: 'categoryTheme',
    filter: 'Dinner Cruises',
    img: 'https://images.hornblower.com/fit-in/200x200/images/tours/hbmdr/06516a0e-f6f0-4585-95d8-9798763eb1f4.jpeg'
  },
  {
    refinement: 'categoryTheme',
    label: 'Sightseeing Tours',
    filter: 'Sightseeing Tour',
    img: 'https://images.hornblower.com/fit-in/200x200/images/tours/ventr/9c6c079b-709e-4d21-98ac-9cf841e923f5.jpg'
  },
  {
    refinement: 'categoryTheme',
    filter: 'Food Tours',
    img: 'https://images.hornblower.com/fit-in/200x200/images/tours/viator/7873169e-8b1e-42f4-9cce-0fe1f2aa79d5.jpg'
  },
  {
    refinement: 'categoryTheme',
    label: 'Attraction Tickets',
    filter: 'Attractions',
    img: 'https://images.hornblower.com/fit-in/800x600/images/tours/hwlkus/90970533-c30b-4c5f-9de1-8bfb05d0bc2c.jpeg'
  },
  {
    refinement: 'categoryTheme',
    filter: 'Holiday Cruises',
    img: 'https://images.hornblower.com/fit-in/200x200/images/tours/hbmdr/291be814-b6a3-4000-af1d-afdaa36b6c10.jpeg'
  }
];

export const DEFAULT_FEATURED_FILTERS_FOR_HIERARCHICAL = [
  {
    refinement: 'hierarchical.lvl0',
    label: 'Dinner Cruises',
    filter: 'Food & Drink > Dining Cruises',
    img: 'https://images.hornblower.com/fit-in/200x200/images/tours/hbmdr/06516a0e-f6f0-4585-95d8-9798763eb1f4.jpeg'
  },
  {
    refinement: 'hierarchical.lvl0',
    label: 'Sightseeing Tours',
    filter: 'Sightseeing & Guided Tours > Sightseeing',
    img: 'https://images.hornblower.com/fit-in/200x200/images/tours/ventr/9c6c079b-709e-4d21-98ac-9cf841e923f5.jpg'
  },
  {
    refinement: 'hierarchical.lvl0',
    label: 'Food & Drink',
    filter: 'Food & Drink',
    img: 'https://images.hornblower.com/fit-in/200x200/images/tours/viator/7873169e-8b1e-42f4-9cce-0fe1f2aa79d5.jpg'
  },
  {
    refinement: 'hierarchical.lvl0',
    label: 'Holiday Cruises',
    filter: 'Cruises & Ferries > Cruises > Holiday Cruises',
    img: 'https://images.hornblower.com/fit-in/200x200/images/tours/hbmdr/291be814-b6a3-4000-af1d-afdaa36b6c10.jpeg'
  }
];

export const INACTIVE_REFINEMENTS_TO_HIDE = { specialCouponCategories: true };

export const WEB_SALES_BANNER_OPTIONS = [
  { id: 'almostSoldOut', name: 'Almost Sold Out', theme: 'chipColor2' },
  { id: 'bestseller', name: 'Bestseller', theme: 'chipColor3' },
  { id: 'booked10TimesToday', name: 'Booked 10 Times Today', theme: 'chipColor3' },
  { id: 'dealOfTheDay', name: 'Deal of the Day', theme: 'chipColor3' },
  { id: 'dealOfTheMonth', name: 'Deal of the Month', theme: 'chipColor3' },
  { id: 'dealOfTheWeek', name: 'Deal of the Week', theme: 'chipColor3' },
  { id: 'lastMinuteDeal', name: 'Last Minute Deal', theme: 'chipColor3' },
  { id: 'hotDeal', name: 'Hot Deal', theme: 'chipColor3' },
  { id: 'newTour', name: 'New Tour', theme: 'chipColor3' },
  { id: 'featuredExperience', name: 'Featured Experience', theme: 'chipColor3' },
  { id: 'saveNow', name: 'Save Now', theme: 'chipColor3' },
  { id: 'coronation', name: 'Coronation', theme: 'chipColor3' },
  { id: 'discountedOffer', name: 'Discounted Offer', theme: 'chipColor2' },
  { id: 'highlyRecommended', name: 'Highly Recommended', theme: 'chipColor3' },
  { id: 'hotSeller', name: 'Hot Seller', theme: 'chipColor2' },
  { id: 'lastFewSeats', name: 'Last Few Seats', theme: 'chipColor2' },
  { id: 'limitedAvailability', name: 'Limited Availability', theme: 'chipColor2' },
  { id: 'limitedOffer', name: 'Limited Offer', theme: 'chipColor2' },
  { id: 'limitedSeats', name: 'Limited Seats', theme: 'chipColor2' },
  { id: 'lunchIncluded', name: 'Lunch Included', theme: 'chipColor4' },
  { id: 'mostPopular', name: 'Most Popular', theme: 'chipColor2' },
  { id: 'newFor2022', name: 'New for 2022', theme: 'chipColor2' },
  { id: 'offerMustEnd', name: 'Offer Must End', theme: 'chipColor2' },
  { id: 'only7PlacesLeft', name: 'Only 7 Places Left', theme: 'chipColor2' },
  { id: 'packageOfTheMonth', name: 'Package of the Month', theme: 'chipColor3' },
  { id: 'packageOfTheWeek', name: 'Package of the Week', theme: 'chipColor3' },
  { id: 'popularPick', name: 'Popular Pick', theme: 'chipColor3' },
  { id: 'popularTour', name: 'Popular Tour', theme: 'chipColor3' },
  { id: 'salePrice', name: 'Sale Price', theme: 'chipColor2' },
  { id: 'sellingFast', name: 'Selling Fast', theme: 'chipColor2' },
  { id: 'multiPortPackages', name: 'Multi-Port Package', theme: 'chipColor2' },
  { id: 'specialOffer', name: 'Special Offer', theme: 'chipColor3' },
  { id: 'visasIncluded', name: 'Visas Included', theme: 'chipColor4' },
  { id: 'twentyFivePercentOff', name: '25% Off', theme: 'chipColor4' },
  { id: 'fiftyPercentOff', name: '50% Off', theme: 'chipColor4' },
  { id: 'fortyPercentOff', name: '40% Off', theme: 'chipColor4' },
  { id: 'twentyPercentOff', name: '20% Off', theme: 'chipColor4' },
  { id: 'fifteenPercentOff', name: '15% Off', theme: 'chipColor4' },
  { id: 'thirtyPercentOff', name: '30% Off', theme: 'chipColor4' },
  { id: 'limitedTimeOffer', name: 'Limited-Time Offer', theme: 'chipColor4' },
  { id: '2For1Tours', name: '2 for 1 Tours', theme: 'chipColor4' },
  { id: 'packageSavings', name: 'Package Savings', theme: 'chipColor4' },
  { id: 'kingsCoronationCruise', name: 'King\'s Coronation Cruise', theme: 'chipColor4' },
  { id: 'ad', name: 'Ad', theme: 'chipColor3' }
];

export const SEARCH_EXPERIENCES_FILTER_DEFAULT_MAX_HEIGHT = 874.5;

export const SEARCH_EXPERIENCES_FILTER_DEFAULT_SM_HEIGHT = '100%';

export const SEARCH_EXPERIENCES_FILTER_DEFAULT_HEIGHT = '50%';

export const SEARCH_EXPERIENCES_FILTER_EXCLUDE_VALUES = ['Special Offers'];

export const MEMBERSHIP_COST_RATES = {
  GOLD: 28092,
  SILVER: 28093
};

export const WAIT_LIST_STATUS = {
  IN_QUEUE: 'inQueue',
  TICKETS_ON_HOLD: 'ticketsOnHold',
  EXPIRED: 'expired',
  PURCHASED: 'purchased'
};

export const NEEDS_REVIEW_ORDER_STATUS = {
  CONFIRMED_NEEDS_REVIEW: 'Confirmed/Needs Review'
};

export const PAYMENT_TYPES = {
  NEW_CREDIT_CARD: 'newCreditCard', // for refunding to new credit card
  CREDIT_CARD_EMV: 'emv',
  NETEPAY: 'netepay',
  CREDIT_CARD: 'creditCard',
  ALIPAY: 'alipay',
  ALIPAY_IN_PERSON: 'alipayInPerson',
  INVOICE: 'invoice',
  OVER_UNDER: 'overUnder',
  OVERAGE_SHORTAGE: 'overageShortage',
  CHECK: 'echeck',
  CHECK_EXTERNAL: 'check',
  GIFTCARD: 'giftcard',
  LOYALTY: 'loyaltyPoints',
  REFUND: 'refund',
  CASH: 'cash',
  CREDIT_CARD_EXTERNAL: 'creditCardExternal',
  CASHIERS_CHECK: 'cashiersCheck',
  ACH_WIRE: 'achWire',
  COMP: 'comp',
  ONBOARD_REVENUE: 'ONBOARD_REVENUE',
  CHARTER_PROPOSAL: 'charterProposal',
  SEND_PROPOSAL: 'sendProposal',
  ADD_TO_PROPOSAL: 'addToProposal',
  NO_PAYMENT_ORDER: 'noPaymentOrder',
  CREDIT_CARD_CRC: 'ccSalesforce',
  NO_SHOW: 'noShow',
  TRANSFER: 'transfer',
  GROUP_RESERVATION: 'groupReservation',
  APPLE_PAY: 'applePay',
  GOOGLE_PAY: 'googlePay',
  STORED_PAYMENT: 'storedPayment',
  STORED_ORDER_PAYMENT: 'storedOrderPayment',
  STORED_UNIVERSAL_CUSTOMER_PAYMENT: 'storedUniversalCustomerPayment',
  COMPANY_BALANCE: 'companyBalance',
  SPLIT_PAYMENT: 'splitPayment',
  ORDER_BALANCE: 'orderBalance',
  MAGTEK: 'magtek',
  PAY_LATER: 'payLater',
  PAY_AFTER_EVENT: 'payAfterEvent',
  DEPOSIT: 'deposit',
  PRIVATE_TOUR_REQUEST: 'privateTourRequest',
  PUBLIC_TOUR_REQUEST: 'publicTourRequest',
  MONTHLY_PAYMENT: 'monthlyPayment',
  SATISPAY: 'satispay',
  BRAINTREE: 'braintree',
  CART_WALLET: 'cartWallet',
  PAYPAL: 'paypal',
  VENMO: 'venmo',
  AFFIRM: 'affirm',
  GLOBEPAY: 'globePay',
  GLOBEPAY_ALIPAY: 'globepayAlipay',
  GLOBEPAY_WECHATPAY: 'globepayWechatPay'
};

export const TRIP_TYPES = {
  ONE_WAY: 'oneWay',
  ROUND_TRIP: 'roundTrip'
};

export const COST_RATE_TYPES = {
  GROUPS: 10,
  PRICE_BAND_1: 20,
  PRICE_BAND_2: 21,
  PRICE_BAND_3: 22,
  PRICE_BAND_4: 23,
  PRICE_BAND_5: 24,
  PRICE_BAND_6: 25,
  PRICE_BAND_7: 26,
  PRICE_BAND_8: 27,
  PRICE_BAND_9: 28,
  PRICE_BAND_10: 29
};

export const EVENT_STATUS = {
  TENTATIVE: 'tentative',
  PENDING: 'pending',
  OPTION2: 'option2',
  OPTION3: 'option3',
  OPTION4: 'option4',
  CONFIRMED: 'confirmed',
  CANCELED: 'canceled',
  CLOSED: 'closed',
  CANCELLATION_IN_PROGRESS: 'cancellation_in_progress',
  READY_TO_RECOGNIZE: 'ready_to_recognize',
  SETTLED: 'settled',
  OPEN_DATE: 'open_date'
};

export const ORDER_STATUS = {
  QUOTE: 'Waitlist / Quote',
  TENTATIVE: 'Tentative',
  ACTIVE: 'Confirmed',
  CANCELED: 'Cancelled',
  CANCELED_RETAINED_DEPOSIT: 'Retained Deposit'
};

export const CRUISE_TYPES = {
  BRUNCH: 'brunch',
  LUNCH: 'lunch',
  DINNER: 'dinner',
  COCKTAIL: 'cocktail',
  SIGHTSEEING: 'shr',
  NIGHTLIFE: 'nm',
  SPECIAL: 'special'
};

export const SF_EVENT_TYPES = {
  DECK_CHARTER: 'deckCharter',
  FULL_SHIP_CHARTER: 'fullShipCharter',
  PUBLIC_SIGHTSEEING: 'publicSightseeing',
  PUBLIC_DINING: 'publicDining',
  PUBLIC_FERRY: 'publicFerry',
  PUBLIC_WATER_TAXI: 'publicWaterTaxi',
  INERNAL_USE: 'internalUse',
  SPECIAL_EVENTS: 'specialEvents',
  PROMOTIONAL: 'promotional',
  PLACEHOLDER: 'placeholder'
};

export const VENDOR_ORDER_STATUS = {
  NEW: 'new',
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  NEEDS_CONFIRMATION: 'needs_confirmation',
  DENIED: 'denied',
  CANCELLED: 'cancelled'
};

export const BRANDS_FOR_QUICK_FILTERS = [
  {
    name: 'City Cruises',
    img: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/city-cruises-logo.png',
    activeImg: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/city-cruises-logo-full-color.png',
    value: 'City Cruises,City Cruises UK',
    subtext: 'Dining & Sightseeing Cruises',
    pctOff: 50
  },
  {
    name: 'devour',
    img: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/devour-logo.png',
    activeImg: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/devour-logo-full-color.png',
    value: 'Devour Tours',
    subtext: 'Food & Drink Experiences',
    pctOff: 15
  },
  {
    name: 'Walks',
    img: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/walks-logo.png',
    activeImg: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/walks-logo-full-color.png',
    value: 'Walks',
    subtext: 'Walking\nTours',
    pctOff: 15
  }
];

export const UK_CITIES_FOR_QUICK_FILTERS = [
  {
    name: 'London',
    img: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/algoliaAssets/london_tile.png',
    value: 'London (Central)',
    descriptions: [
      { text: '25% off Thamesjet and Lunch Cruises.',
        code: 'CYBER25' },
      { text: '50% 24h River Pass (for all travel dates until 31 March 2024).',
        code: 'CYBER50' }]
  },
  {
    name: 'Poole',
    img: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/algoliaAssets/poole_tile.png',
    value: 'Poole',
    descriptions: [
      { text: '50% off Sightseeing Cruises. For all travel dates until 31 March 2024.',
        code: 'CYBER50' }]
  },
  {
    name: 'York',
    img: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/algoliaAssets/york_tile.png',
    value: 'York',
    descriptions: [
      { text: '50% off Sightseeing Cruises. For all travel dates until 31 March 2024.',
        code: 'CYBER50' },
      { text: '25% off Dining and Self-Drive. For all travel dates until 31 March 2024.',
        code: 'CYBER25' }]
  }
];

export const ELLIPSE_ICON = 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/algoliaAssets/ellipse.png';

export const UTM_PARAMETERS = {
  utm_source: true,
  utm_medium: true,
  utm_campaign: true,
  utm_content: true,
  sfmc_j: true,
  sfmc_s: true,
  sfmc_l: true,
  sfmc_jb: true,
  sfmc_mid: true,
  sfmc_u: true,
  gad_source: true,
  gclid: true,
  utm_id: true,
  utm_source_platform: true,
  utm_term: true,
  wbraid: true,
  msclid: true,
  showCurrentlyViewing: true,
  showBookedToday: true,
  dclid: true,
  fbclid: true
};

export const CATEGORY_QUICK_FILTER_OPTIONS = [
  {
    label: 'Dining Cruises',
    value: 'Dinner Cruises, Brunch Cruises, Lunch Cruises',
    image: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/algoliaAssets/categoryQuickFilterImages/Dining+cruise.svg'
  },
  {
    label: 'Sightseeing',
    value: 'Sightseeing Cruises',
    image: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/algoliaAssets/categoryQuickFilterImages/sightseeing.svg'
  },
  {
    label: 'Brunch Cruises',
    value: 'Brunch Cruises',
    image: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/algoliaAssets/categoryQuickFilterImages/Brunch.svg'
  },
  {
    label: 'Lunch Cruises',
    value: 'Lunch Cruises',
    image: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/algoliaAssets/categoryQuickFilterImages/Lunch.svg'
  },
  {
    label: 'Dinner Cruises',
    value: 'Dinner Cruises',
    image: 'https://prodv3-hornblower-assets.s3.us-west-2.amazonaws.com/images/algoliaAssets/categoryQuickFilterImages/Dinner+cruise.svg'
  }
];

export const HIDE_CATEGORY_FOR_WEB = [
  'Valentine\'s Day',
  'Easter',
  'Thanksgiving',
  'New Year\'s Eve',
  'Mother\'s Day',
  'July 4th',
  'Christmas',
  'Father\'s Day'
];

export const VWO_CAMPAIGNS = {
  DISPLAY_WITH_ALL_FEES: 'display_with_all_fees',
  ALGOLIA_FEATURED_TILES: 'algolia_featured_tiles'
};

export const INCLUDED_PORTS_FOR_DISPLAY_PRICE_CAMPAIGN = [
  '.*/san-diego/city-cruises.*',
  '.*/san-francisco/city-cruises.*',
  '.*/new-york/city-cruises.*',
  '.*/philadelphia/city-cruises.*',
  '.*/washington-dc/city-cruises.*'
];

export const INCLUDED_PORTS_FOR_FEATURED_TILES_CAMPAIGN = [
  '.*/san-diego/city-cruises.*',
  '.*/norfolk/city-cruises.*',
  '.*/boston/city-cruises.*',
  '.*/baltimore/city-cruises.*'
];

export const INCLUDED_CITIES_FOR_DISPLAY_PRICE_CAMPAIGN = [
  'San Diego',
  'San Francisco',
  'Washington DC',
  'New York City',
  'Philadelphia'
];
