/* eslint-disable max-lines */
import React, { memo } from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import ResultItem from './ResultItem/ResultItem';
import { isVwoTestActive } from '../../../util/isVwoTestActive';
import { INCLUDED_PORTS_FOR_FEATURED_TILES_CAMPAIGN, VWO_CAMPAIGNS } from '../../lib/shared/enums';
const styles = () => ({
});

const ResultsList = ({
  hits,
  siteId,
  abTestVariant,
  tealiumTrack,
  recentBookingsThreshold,
  sellOutThreshold,
  tourCurrencies,
  showPriceWithFees,
  isMobileView,
  searchIndex,
  sendAlgoliaEvent,
  setAlgoliaSearchInfo,
  searchExperienceV3IsEnabled,
  showEmbedSocialReview,
  allowedDisplayLabels,
  shouldHideAdlabels,
  shouldAddCtaButton,
  shouldRedirectAd,
  toShowCentsPropertyIds,
  aliases,
  showNoResult = false,
  chatkey,
  showPromoCodeEligible,
  showNewTextForNoResultFound,
  hasSearchTerm,
  enableDarkThemeTiles,
  enableMobileTileVariant2,
  enableMobileTileVariant3,
  isXsView,
  exclusiveDealBannerText,
  allowFilterDatesWithPermalink,
  showCurrentlyViewing,
  currentLocale,
  isFirstPage,
  startDate,
  endDate,
  cityForNoResult,
  noInventoryFlag,
  hasSearchResults,
  searchWithQueryAndRange,
  isDisplayPriceWithFeesCampaignActive,
  isInfiniteHits,
  showBookedToday
}) => (hits || []).map((hit, index) => {
  const {
    objectID,
    title,
    tileTitle,
    shortDescription,
    images,
    tiles,
    bookingTypeId,
    displayPrice,
    ctaButtonContent,
    defaultCurrency,
    permalink,
    propertyId,
    city,
    state,
    country,
    webCategoriesMain,
    exclusiveDeal,
    displayLabel,
    displayIcon,
    durationTiming,
    durationInMins,
    crossSellPermalinks,
    tbdPrice,
    numberOfRecentBookings,
    availabilityPct,
    discountedPrice,
    description,
    tileBannerLink,
    flipCard,
    propBookingType,
    discountedPriceWithFees,
    displayPriceWithFees,
    shouldShowFeeText,
    justFeePercentage,
    showStrikeThroughDiscountedPrice,
    __queryID,
    __position,
    embedReviewId,
    containsSpecialCoupon,
    containsDoubleRewards,
    webCategoriesSub,
    upToSavePercentage,
    tagDescription,
    tagColor,
    overrideDefaultTileTag,
    specialCouponBannerText,
    tagTextColor,
    isPriceInclusiveOfFees,
    localeTitles,
    localeTileTitles,
    isAdTile,
    adTileTitle,
    adTileSubtext,
    adTileMobileText,
    adTileButtonCtaText,
    adTileImage,
    adTileLink,
    brandTag
  } = hit;
  const isFeaturedTile = isVwoTestActive({
    campaignCookie: VWO_CAMPAIGNS.ALGOLIA_FEATURED_TILES,
    matchUrl: window.location.pathname,
    matchToUrls: INCLUDED_PORTS_FOR_FEATURED_TILES_CAMPAIGN
  }) && (isFirstPage || isInfiniteHits) && (index === 1 || index === 0);
  return (
    <ResultItem
      key={`hit-${objectID}`}
      localeTitles={localeTitles}
      localeTileTitles={localeTileTitles}
      currentLocale={currentLocale}
      tealiumTrack={tealiumTrack}
      showCurrentlyViewing={showCurrentlyViewing}
      showBookedToday={showBookedToday}
      chatkey={chatkey}
      showPromoCodeEligible={showPromoCodeEligible}
      showPriceWithFees={showPriceWithFees}
      images={images}
      tiles={tiles}
      title={title}
      tileTitle={tileTitle}
      objectID={objectID}
      shortDescription={shortDescription}
      bookingTypeId={bookingTypeId}
      propertyId={propertyId}
      displayPrice={displayPrice}
      defaultCurrency={defaultCurrency}
      permalink={permalink}
      city={(((aliases || {})[propertyId] || {})[bookingTypeId] || {}).city || city}
      state={state}
      country={country}
      webCategoriesMain={webCategoriesMain}
      exclusiveDeal={exclusiveDeal}
      displayLabel={
        shouldHideAdlabels && shouldAddCtaButton && displayLabel ?
          displayLabel : (allowedDisplayLabels || displayLabel)
      }
      displayIcon={displayIcon}
      durationTiming={durationTiming}
      durationInMins={durationInMins}
      crossSellPermalinks={crossSellPermalinks}
      siteId={siteId}
      abTestVariant={abTestVariant}
      tbdPrice={tbdPrice}
      numberOfRecentBookings={numberOfRecentBookings}
      availabilityPct={availabilityPct}
      recentBookingsThreshold={recentBookingsThreshold}
      sellOutThreshold={sellOutThreshold}
      allowFilterDatesWithPermalink={allowFilterDatesWithPermalink}
      startDate={startDate}
      endDate={endDate}
      tourCurrencies={tourCurrencies}
      propBookingType={propBookingType}
      discountedPrice={discountedPrice}
      description={description}
      flipCard={flipCard}
      tileBannerLink={tileBannerLink}
      discountedPriceWithFees={discountedPriceWithFees}
      displayPriceWithFees={displayPriceWithFees}
      showStrikeThroughDiscountedPrice={showStrikeThroughDiscountedPrice}
      isMobileView={isMobileView}
      ctaButtonContent={ctaButtonContent}
      shouldRedirectAd={shouldRedirectAd}
      shouldAddCtaButton={shouldAddCtaButton}
      __queryID={__queryID}
      __position={__position}
      hit={hit}
      searchIndex={searchIndex}
      sendAlgoliaEvent={sendAlgoliaEvent}
      setAlgoliaSearchInfo={setAlgoliaSearchInfo}
      searchExperienceV3IsEnabled={searchExperienceV3IsEnabled}
      embedReviewId={showEmbedSocialReview && embedReviewId}
      containsSpecialCoupon={containsSpecialCoupon}
      containsDoubleRewards={containsDoubleRewards}
      shouldHideAdlabels={shouldHideAdlabels}
      webCategoriesSub={webCategoriesSub}
      upToSavePercentage={upToSavePercentage}
      tagDescription={tagDescription}
      tagColor={tagColor}
      overrideDefaultTileTag={overrideDefaultTileTag}
      specialCouponBannerText={specialCouponBannerText}
      tagTextColor={tagTextColor}
      enableDarkThemeTiles={enableDarkThemeTiles}
      enableMobileTileVariant2={enableMobileTileVariant2}
      isXsView={isXsView}
      enableMobileTileVariant3={enableMobileTileVariant3}
      showNewTextForNoResultFound={showNewTextForNoResultFound}
      cityForNoResult={cityForNoResult}
      hasSearchTerm={hasSearchTerm}
      showNoResult={showNoResult}
      exclusiveDealBannerText={exclusiveDealBannerText}
      noInventoryFlag={noInventoryFlag}
      hasSearchResults={hasSearchResults}
      searchWithQueryAndRange={searchWithQueryAndRange}
      isPriceInclusiveOfFees={isPriceInclusiveOfFees}
      justFeePercentage={justFeePercentage}
      shouldShowFeeText={shouldShowFeeText}
      isAdTile={isAdTile}
      adTileTitle={adTileTitle}
      adTileSubtext={adTileSubtext}
      adTileMobileText={adTileMobileText}
      adTileButtonCtaText={adTileButtonCtaText}
      adTileImage={adTileImage}
      adTileLink={adTileLink}
      isFeaturedTile={isFeaturedTile}
      toShowPriceCents={
        Array.isArray(toShowCentsPropertyIds) && (toShowCentsPropertyIds || []).includes(propertyId || '')
      }
      brandTag={brandTag}
      isDisplayPriceWithFeesCampaignActive={isDisplayPriceWithFeesCampaignActive}
      itemIdx={index}
    />
  );
});

ResultsList.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default memo(withStyles(styles)(ResultsList));
