/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import React, { memo, useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Text } from 'react-localize';
import isEqual from 'lodash/isEqual';
import { loadEmbedSocialReview } from '../../lib/embedSocial';
import classNames from 'classnames';
import { Card, CardContent, CardMedia } from '@mui/material';
import { connectStateResults } from 'react-instantsearch-dom';
import search from '../../../util/getProductsOnHorizon';
import searchFlexibleDates from '../../../util/getFlexibleDatesData';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import cookie from 'cookiejs';
import { VWO_CAMPAIGNS } from '../../lib/shared/enums';
import ResultsList from './ResultsList';

const styles = theme => ({
  card: {
    height: 300,
    width: '100%',
    backgroundColor: '#ffffff',
    borderRadius: 16,
    position: 'relative',
    transition: 'margin 250ms',
    marginTop: theme.spacing(4),
    overflow: 'visible'
  },
  cardMobile: {
    marginTop: '0px'
  },
  media: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    height: '66%',
    width: '100%',
    backgroundPosition: 'initial',
    background: 'radial-gradient(circle at 18.7% 37.8%, rgb(245, 245, 245) 0%, rgb(233, 236, 240) 90%);'
  },
  content: {
    background: theme.palette.common.white,
    paddingTop: theme.spacing(1)
  },
  eventNameSkeleton: {
    height: theme.spacing(2.5),
    width: '75%',
    backgroundColor: 'rgb(245, 245, 245)'
  },
  cityNameSkeleton: {
    height: theme.spacing(1.5),
    width: '25%',
    marginTop: theme.spacing(2.5),
    backgroundColor: 'rgb(245, 245, 245)'
  },
  productsOnHorizonTitle: {
    paddingBottom: '35px'
  },
  noResultsProductsOnHorizonTitles: {
    paddingBottom: '35px'
  },
  noResultsProductsOnHorizonContainer: {
    backgroundColor: '#234d580d',
    borderRadius: '25px',
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2)
    }
  },
  onTheHorizonContainer: {
    marginTop: theme.spacing(3),
    backgroundColor: '#234d580d',
    borderRadius: '25px',
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2)
    }
  },
  horizonHeader: {
    fontSize: '28px !important',
    color: '#000000cc !important',
    marginTop: '0px !important'
  },
  felxibleDateMessage: {
    paddingBottom: '30px'
  },
  highlightedTile: {
    boxShadow: '0px 0px 8px #FFC055'
  }
});

const COOKIE_NAME = 'cex_campaign';
let isDisplayPriceWithFeesCampaignActive = false;
try {
  isDisplayPriceWithFeesCampaignActive = cookie.get(COOKIE_NAME) === VWO_CAMPAIGNS.DISPLAY_WITH_ALL_FEES;
} catch (err) {
  // eslint-disable-next-line no-console
  console.log('cookie error', err);
}

const Results = ({
  hits,
  siteId,
  abTestVariant,
  tealiumTrack,
  recentBookingsThreshold,
  sellOutThreshold,
  tourCurrencies,
  showPriceWithFees,
  isMobileView,
  adsTile,
  searchIndex,
  sendAlgoliaEvent,
  setAlgoliaSearchInfo,
  searchExperienceV3IsEnabled,
  showEmbedSocialReview,
  allowedDisplayLabels,
  shouldHideAdlabels,
  shouldAddCtaButton,
  shouldRedirectAd,
  showToursWithAvailabilityOnly,
  fixedHeight,
  setFixedHeight,
  toShowCentsPropertyIds,
  aliases,
  showNoResult = false,
  chatkey,
  showPromoCodeEligible,
  classes,
  hitsPerPage,
  disableScrollUp,
  onScrollUp,
  enableProductsOnHorizon,
  searchState,
  elasticSearchIndex,
  isInfiniteHits = false,
  hasMore = true,
  showNewTextForNoResultFound,
  hasSearchTerm,
  setActiveRefinements,
  enableDarkThemeTiles,
  enableMobileTileVariant2,
  enableMobileTileVariant3,
  isXsView,
  exclusiveDealBannerText,
  allowFilterDatesWithPermalink,
  showCurrentlyViewing,
  currentLocale,
  isFirstPage,
  searchResults,
  showBookedToday
}) => {
  const [newHits, setNewHits] = useState([]);
  const [productsOnHorizon, setProductsOnHorizon] = useState([]);
  const [noResultData, setNoResultData] = useState([]);
  const [noInventoryFlag, setNoInventoryFlag] = useState(false);
  const [hasSearchResults, setHasSearchResults] = useState(false);
  const [queryId, setQueryId] = useState('');
  const {
    query,
    range,
    refinementList
  } = searchState || {};

  const cityForNoResult = refinementList?.city?.[0] || '';
  const searchWithQueryAndRange = !!query && (range?.availableDates?.min || range?.availableDates?.max);
  const startDate = range?.availableDates?.min && moment(range?.availableDates?.min, 'YYYYMMDD').format('MM/DD/YYYY');
  const endDate = range?.availableDates?.max && moment(range?.availableDates?.max, 'YYYYMMDD').format('MM/DD/YYYY');

  const { queryID: searchResultsQueryId, disjunctiveFacets } = searchResults || {};
  if (searchResultsQueryId !== queryId) {
    setQueryId(searchResultsQueryId);
    const updatedFacets = (disjunctiveFacets || []).reduce((accum, facet) => {
      accum.add(facet.name);
      return accum;
    }, new Set());
    setActiveRefinements(updatedFacets);
  }
  if (searchResults && !searchResults.nbHits && hasSearchResults) {
    setHasSearchResults(false);
  } else if (searchResults && searchResults.nbHits && !showNoResult && !hasSearchResults) {
    setHasSearchResults(true);
  }

  const fetchProducts = async() => {
    if (((hits.length < hitsPerPage && hasSearchResults) || (isInfiniteHits && !hasMore)) && enableProductsOnHorizon) {
      const result = await search({ searchState, index: elasticSearchIndex });
      const { hits: horizonHits = [] } = result || {};
      setProductsOnHorizon(horizonHits.slice(0, isInfiniteHits ? hitsPerPage : hitsPerPage - hits.length));
    } else if (enableProductsOnHorizon) {
      setProductsOnHorizon([]);
    }
  };

  function dateProximity(date) {
    const minDate = moment(range?.availableDates?.min, 'YYYYMMDD') || moment().format('YYYYMMDD');
    return Math.abs(minDate.diff(date, 'days'));
  }

  const fetchNoResultData = async() => {
    // Only run this if necessary - reduce API calls to Algolia ($$$)
    if (queryId && hits && !hits.length) {
      const { result, noInventory } = await searchFlexibleDates({ searchState, index: elasticSearchIndex });
      const { hits: noResultDataHits = [] } = result || {};
      setNoInventoryFlag(noInventory);
      const sortedData = (noResultDataHits || []).sort((first, second) => {
        const proximityA = Math.min(...first.availableDates.map(date => dateProximity(moment(date, 'YYYYMMDD'))));
        const proximityB = Math.min(...second.availableDates.map(date => dateProximity(moment(date, 'YYYYMMDD'))));
        return proximityA - proximityB;
      });
      setNoResultData(sortedData);
    }
  };

  useEffect(() => {
    if (!(hits || []).length) {
      setNewHits([]);
      if (isMobileView && setFixedHeight && fixedHeight) {
        setFixedHeight(false);
      }
    }
    if (!isEqual(newHits, hits) && enableProductsOnHorizon) {
      fetchProducts();
    }
    if (showNewTextForNoResultFound) {
      fetchNoResultData();
    }
    if ((hits && hits.length && !isEqual(newHits, hits))) {
      let tmpHits = hits;
      if (showToursWithAvailabilityOnly) {
        tmpHits = hits.filter(({ availableDates = [] }) => availableDates.length && availableDates.length > 0);
      }
      if (tmpHits && Array.isArray(adsTile) && (adsTile || []).length > 0) {
        (adsTile).forEach(({ adsTileNo, adsImage, adslink, ctaButtonContent }, idx) => {
          if (!isNaN(parseInt(adsTileNo))) {
            (tmpHits || []).splice(parseInt(adsTileNo), 0, {
              objectID: `hit-${idx}`,
              tiles: [adsImage],
              permalink: adslink,
              displayLabel: ['ad'],
              images: [adsImage],
              isAdTile: true,
              ctaButtonContent
            });
            if ((tmpHits || []).length > 12) {
              (tmpHits || []).pop();
            }
          }
        });
      }
      if (showEmbedSocialReview && !isEqual(newHits, tmpHits)) {
        loadEmbedSocialReview();
      }

      if (isMobileView && setFixedHeight) {
        if (tmpHits.length < 4 && fixedHeight) {
          setFixedHeight(false);
        }
        if (tmpHits.length >= 4 && !fixedHeight) {
          setFixedHeight(true);
        }
      }
      if (!disableScrollUp && tmpHits && Array.isArray(adsTile) && tmpHits.length < hitsPerPage) {
        onScrollUp();
      }
      setNewHits(tmpHits);
    }
  }, [hits, enableProductsOnHorizon]);

  useEffect(() => {
    fetchProducts();
  }, [enableProductsOnHorizon]);

  const resultsListProps = {
    siteId,
    abTestVariant,
    tealiumTrack,
    recentBookingsThreshold,
    sellOutThreshold,
    tourCurrencies,
    showPriceWithFees,
    searchIndex,
    sendAlgoliaEvent,
    setAlgoliaSearchInfo,
    searchExperienceV3IsEnabled,
    showEmbedSocialReview,
    allowedDisplayLabels,
    shouldHideAdlabels,
    shouldAddCtaButton,
    shouldRedirectAd,
    toShowCentsPropertyIds,
    aliases,
    showNoResult,
    chatkey,
    showPromoCodeEligible,
    classes,
    showNewTextForNoResultFound,
    hasSearchTerm,
    enableDarkThemeTiles,
    enableMobileTileVariant2,
    enableMobileTileVariant3,
    exclusiveDealBannerText,
    allowFilterDatesWithPermalink,
    showCurrentlyViewing,
    currentLocale,
    isFirstPage,
    startDate,
    endDate,
    cityForNoResult,
    noInventoryFlag,
    hasSearchResults,
    searchWithQueryAndRange,
    isDisplayPriceWithFeesCampaignActive,
    isXsView,
    isMobileView,
    isInfiniteHits,
    showBookedToday
  };
  const noResultsDataList = <ResultsList hits={noResultData} {...resultsListProps} />;
  const productsOnHorizonList = <ResultsList hits={productsOnHorizon} {...resultsListProps} />;
  const hitsResultsDataList = <ResultsList hits={newHits} {...resultsListProps} />;

  const SkeletonLoader = () => (
    <Card
      className={classNames(classes.card, 'cardV2Inline', { [classes.cardMobile]: isMobileView })}
    >
      <CardMedia className={classes.media}/>
      <CardContent>
        <div className={classNames('tileTitleStyling', classes.eventNameSkeleton)}></div>
        <div className={classes.cityNameSkeleton}></div>
      </CardContent>
    </Card>
  );
  const skeletonLoaders = Array.from(Array(hitsPerPage).keys()).map(number => (
    <Grid
      item
      key={`skeleton-loader-${number}`}
      xs={3}
      sm={3}
      md={3}
      lg={4}
      xl={3}
      className={classNames({
        [classes.firstMobileResult]: number === 0,
        tileGridResult: true
      })}
    >
      <SkeletonLoader key={number}/>
    </Grid>
  ));

  const renderNoResults = loading => (!loading ? <Grid
    item
    xs={12}
  >
    <Typography gutterBottom variant="h5" color="primary">
      <Text message={showNewTextForNoResultFound ? 'noResultsFoundWithFlag.title' : 'noResultsFound.title'}/>
    </Typography>
    <Typography gutterBottom variant="body2" color="textSecondary" className={classes.felxibleDateMessage}>
      <Text message={showNewTextForNoResultFound ? 'noResultsFoundWithFlag.message' : 'noResultsFound.message'}/>
    </Typography>
    {showNewTextForNoResultFound &&
    (
      noResultData.length > 0 ? <div className={classes.noResultsProductsOnHorizonContainer}>
        { showNewTextForNoResultFound && <>
          <Typography gutterBottom variant="h6" color="black">
            <Text message={(!cityForNoResult || (noInventoryFlag && cityForNoResult)) ? 'trendingNearYou.title' :
              (hasSearchTerm && !noInventoryFlag && !searchWithQueryAndRange ?
                'flexibleDateWithFlagForSearchTerm.title' :
                noInventoryFlag ?
                  'flexibleDateWithFlagForDateAndTerm.title' : 'flexibleDateWithFlag.title') }
            values={[
              ((hasSearchTerm && !searchWithQueryAndRange) || noInventoryFlag) && (cityForNoResult && !noInventoryFlag)
                ? cityForNoResult : '']}
            />
          </Typography>
          <Typography gutterBottom variant="body2" color="textSecondary" className={classes.felxibleDateMessage}>
            <Text message={(!cityForNoResult || (noInventoryFlag && cityForNoResult))
              ? 'trendingNearYou.message' : (noInventoryFlag ?
                'flexibleDateWithFlagForDateAndTerm.message' :
                (hasSearchTerm && !searchWithQueryAndRange) ?
                  'flexibleDateWithFlagForSearchTerm.message' : 'flexibleDateWithFlag.message')}/>
          </Typography></>}
        {!!(noResultData || []).length && showNewTextForNoResultFound &&
      <Grid container spacing={enableMobileTileVariant3 && isXsView ? 1 : 4} className={'searchResultsGrid'}>
        {noResultsDataList}
      </Grid>
        }
      </div>
        :
        (
          <Grid container spacing={2}>
            {skeletonLoaders}
          </Grid>
        )
    )
    }

  </Grid> : (
    Array.from(Array(hitsPerPage).keys()).map(number =>
      <Grid
        item
        key={`skeleton-loader-${number}`}
        xs={12}
        sm={6}
        md={6}
        lg={4}
        xl={3}
        className={classNames({
          [classes.firstMobileResult]: number === 0,
          tileGridResult: true
        })}
      >
        <SkeletonLoader key={number}/>
      </Grid>
    )
  )
  );

  return (searchResults && searchResults.nbHits !== 0 && !showNoResult ? (
    <>
      <Grid container spacing={enableMobileTileVariant3 && isXsView ? 2 : 4} className={'searchResultsGrid'}>
        {hitsResultsDataList}
      </Grid>
      {!!(productsOnHorizon || []).length &&
            <div className={classes.onTheHorizonContainer}>
              <div className={classes.productsOnHorizonTitle}>
                <Typography gutterBottom variant="h4" className={classes.horizonHeader}>
                  <Text message={'productsOnHorizon.header'}/>
                </Typography>
                <Typography gutterBottom variant="body2" color="textSecondary">
                  <Text message={'productsOnHorizon.subheader'}/>
                </Typography>
              </div>
              <Grid container spacing={enableMobileTileVariant3 && isXsView ? 2 : 4} className={'searchResultsGrid'}>
                {productsOnHorizonList}
              </Grid>
            </div>
      }
    </>
  ) : !(productsOnHorizon || []).length ? (
    <Grid container spacing={4} className={'searchResultsGrid'}>
      {renderNoResults((!searchResults && !showNoResult))}
    </Grid>
  ) : (
    <>
      <div className={classes.noResultsProductsOnHorizonContainer}>
        <div className={classes.noResultsProductsOnHorizonTitles}>
          <Typography gutterBottom variant="h4" className={classes.horizonHeader}>
            <Text message={'productsOnHorizon.header'}/>
          </Typography>
          <Typography gutterBottom variant="body2" color="textSecondary">
            <Text message={'productsOnHorizon.subheader'}/>
          </Typography>
        </div>
        <Grid container spacing={enableMobileTileVariant3 && isXsView ? 2 : 4} className={'searchResultsGrid'}>
          {productsOnHorizonList}
        </Grid>
      </div>
    </>
  ));
};

Results.contextTypes = {
  localize: PropTypes.func.isRequired
};

export default memo(withStyles(styles)(connectStateResults(Results)));
